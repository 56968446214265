.h-screen {
  height: 100dvh;
  height: 100vh;
}
.h-screen-imp {
  height: 100dvh !important;
  height: 100vh !important;
}
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #7a7b91;
}
.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #7a7b91;
}
.separator::before {
  margin-right: 1em;
}
.separator::after {
  margin-left: 1em;
}

.full-width {
  width: 100%;
}

.verloop-button {
  background-color: #1f1f1f !important;
  display: none !important; /* overwrite other values*/
  z-index: 99 !important;
  border: 1px solid #4a4a4a !important;
}

.verloop-livechat-logo {
  background-image: url('./new-support.svg') !important;
  background-size: 24px 24px !important;
  background-position: center !important;
}

.dottedButtonClsxLocoOrange {
  border: none !important;
  background-image: repeating-linear-gradient(
      29deg,
      #ff5a12,
      #ff5a12 5px,
      transparent 5px,
      transparent 10px,
      #ff5a12 10px
    ),
    repeating-linear-gradient(
      119deg,
      #ff5a12,
      #ff5a12 5px,
      transparent 5px,
      transparent 10px,
      #ff5a12 10px
    ),
    repeating-linear-gradient(
      209deg,
      #ff5a12,
      #ff5a12 5px,
      transparent 5px,
      transparent 10px,
      #ff5a12 10px
    ),
    repeating-linear-gradient(
      299deg,
      #ff5a12,
      #ff5a12 5px,
      transparent 5px,
      transparent 10px,
      #ff5a12 10px
    ) !important;
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px !important;
  background-position: 0 0, 0 0, 100% 0, 0 100% !important;
  background-repeat: no-repeat !important;
}

.dottedButtonClsxLocoWhite {
  border: none !important;
  background-image: repeating-linear-gradient(
      29deg,
      #ffffff,
      #ffffff 5px,
      transparent 5px,
      transparent 10px,
      #ffffff 10px
    ),
    repeating-linear-gradient(
      119deg,
      #ffffff,
      #ffffff 5px,
      transparent 5px,
      transparent 10px,
      #ffffff 10px
    ),
    repeating-linear-gradient(
      209deg,
      #ffffff,
      #ffffff 5px,
      transparent 5px,
      transparent 10px,
      #ffffff 10px
    ),
    repeating-linear-gradient(
      299deg,
      #ffffff,
      #ffffff 5px,
      transparent 5px,
      transparent 10px,
      #ffffff 10px
    ) !important;
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px !important;
  background-position: 0 0, 0 0, 100% 0, 0 100% !important;
  background-repeat: no-repeat !important;
}

.dottedButtonClsxLocoGreyTExt {
  border: none !important;
  background-image: repeating-linear-gradient(
      29deg,
      #282828,
      #282828 5px,
      transparent 5px,
      transparent 10px,
      #282828 10px
    ),
    repeating-linear-gradient(
      119deg,
      #282828,
      #282828 5px,
      transparent 5px,
      transparent 10px,
      #282828 10px
    ),
    repeating-linear-gradient(
      209deg,
      #282828,
      #282828 5px,
      transparent 5px,
      transparent 10px,
      #282828 10px
    ),
    repeating-linear-gradient(
      299deg,
      #282828,
      #282828 5px,
      transparent 5px,
      transparent 10px,
      #282828 10px
    ) !important;
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px !important;
  background-position: 0 0, 0 0, 100% 0, 0 100% !important;
  background-repeat: no-repeat !important;
}

.dottedButtonClsxLocoError {
  border: none !important;
  background-image: repeating-linear-gradient(
      29deg,
      #ca3838,
      #ca3838 5px,
      transparent 5px,
      transparent 10px,
      #ca3838 10px
    ),
    repeating-linear-gradient(
      119deg,
      #ca3838,
      #ca3838 5px,
      transparent 5px,
      transparent 10px,
      #ca3838 10px
    ),
    repeating-linear-gradient(
      209deg,
      #ca3838,
      #ca3838 5px,
      transparent 5px,
      transparent 10px,
      #ca3838 10px
    ),
    repeating-linear-gradient(
      299deg,
      #ca3838,
      #ca3838 5px,
      transparent 5px,
      transparent 10px,
      #ca3838 10px
    ) !important;
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px !important;
  background-position: 0 0, 0 0, 100% 0, 0 100% !important;
  background-repeat: no-repeat !important;
}

/* React-select UI */
@media only screen and (max-width: 450px) {
  /* .react-select__single-value {
    padding-left: 0 !important;
    margin-left: -6px !important;
    font-weight: bolder;
  }
  .react-select__value-container--is-multi {
    font-weight: bolder;
    padding-left: 0 !important;
    margin-left: -6px !important;
  } */
  .react-select__multi-value__remove {
    display: 'none';
  }
}

@keyframes marquee {
  0% {
    transform: translateX(20%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* removing default autofill color for input fields */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #ffffff;
}

/* 
// Doc: https://docs.google.com/document/d/185xhOhpjumsIYtjxjLDWl5I5riXnlOJOE7YurHJyP_4/edit
// const ALL_FONT_PREFIXES = [
//   { name: 'SuisseIntl-Ultralight', weight: '100' },
//   { name: 'SuisseIntl-Light', weight: '200' },
//   { name: 'SuisseIntl-Book', weight: '300' },
//   { name: 'SuisseIntl-Regular', weight: '400' },
//   { name: 'SuisseIntl-Medium', weight: '500' },
//   { name: 'SuisseIntl-SemiBold', weight: '600' },
//   { name: 'SuisseIntl-Bold', weight: '700' },
//   { name: 'SuisseIntl-Black', weight: '900' },

//   // { name: 'SuisseIntl-Thin', weight: '' },
// ]
// const FONT_SIZES = ['WebS', 'WebM', 'WebXL']

// Class Properties
// font-thin	font-weight: 100;
// font-extralight	font-weight: 200;
// font-light	font-weight: 300;
// font-normal	font-weight: 400;
// font-medium	font-weight: 500;
// font-semibold	font-weight: 600;
// font-bold	font-weight: 700;
// font-extrabold	font-weight: 800;
// font-black	font-weight: 900;
*/

@font-face {
  font-family: 'SuisseIntl';
  src: url('./fonts/SuisseIntl/SuisseIntl-Ultralight-WebM.woff2')
    format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./fonts/SuisseIntl/SuisseIntl-UltralightItalic-WebM.woff2')
    format('woff2');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./fonts/SuisseIntl/SuisseIntl-Light-WebM.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./fonts/SuisseIntl/SuisseIntl-LightItalic-WebM.woff2')
    format('woff2');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./fonts/SuisseIntl/SuisseIntl-Book-WebM.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./fonts/SuisseIntl/SuisseIntl-BookItalic-WebM.woff2')
    format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./fonts/SuisseIntl/SuisseIntl-Regular-WebM.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./fonts/SuisseIntl/SuisseIntl-RegularItalic-WebM.woff2')
    format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./fonts/SuisseIntl/SuisseIntl-Medium-WebM.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./fonts/SuisseIntl/SuisseIntl-MediumItalic-WebM.woff2')
    format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./fonts/SuisseIntl/SuisseIntl-SemiBold-WebM.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./fonts/SuisseIntl/SuisseIntl-SemiBoldItalic-WebM.woff2')
    format('woff2');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./fonts/SuisseIntl/SuisseIntl-Bold-WebM.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./fonts/SuisseIntl/SuisseIntl-BoldItalic-WebM.woff2')
    format('woff2');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./fonts/SuisseIntl/SuisseIntl-Black-WebM.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./fonts/SuisseIntl/SuisseIntl-BlackItalic-WebM.woff2')
    format('woff2');
  font-weight: 900;
  font-style: italic;
}
.radio_button_group span {
  background: #ff5a12;
}

* {
  scrollbar-width: auto;
  scrollbar-color: #212121 transparent;
}
